<template>
    <div class="c-main">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    name: 'EContent',
}
</script>
<style scoped>
    .c-main{
        height: auto;
        position: relative;
    }
</style>